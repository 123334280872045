.p-block {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    justify-content: center;
}

.section-heading {
    margin-top: 20px;
    padding-left: 0px;
    font-size: 36px;
    font-weight: 600;
    text-align: left;
  }
.section-1-description {
    margin-top: 20px;
    padding-left: 0px;
    font-size:20px;
    font-weight: 'normal';
}
.desktop-download-logo {
    margin-top: 20px;
    display: flex;
    padding-left: 0px;
}
.lecture-mode {
    flex:1;
    padding: 20px;
    align-items: center;

}
.talk-to-book{
    flex:2;
    padding:20px;
}
.contain{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
.card-img {
    width: 100%;
    border-top-left-radius: 8px; /* Adjust as needed */
    border-top-right-radius: 8px; /* Adjust as needed */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: block;
}
.card-title {
    font-size: 36px;
    font-weight: 600px;
}
.card-description {
    font-size: 20px;
    font-weight: 'normal';
}
.w-40 {
    width: 40%;
}