@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Stolzl";
  src: url("../public/fonts/3421-font.otf") format("opentype");
}

body {
  font-family: "Stolzl";
}
/* login modal css start */

.modal-title img {
  margin-top: -6px;
}

.swiper-heading span {
  font-size: 26px;
  margin-left: -10px;
  margin-top: 10px;
  font-weight: 600;
}

.range-slider__wrap .range-slider__tooltip {
  z-index: 0 !important;
}

.swiper-heading2 span {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}

.swiper-heading2 img {
  margin-right: -8px;
}

.swiper-heading {
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}

.sign-in {
  padding: 0;
}

.swiper-modal .modal-body {
  padding: 0;
}

.payment-button {
  background: #6366f1;
  height: 68px;
  border-radius: 12px;
  font-size: 25px;
  line-height: 35px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.tinder-baseline h2 {
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 35px;
}

.btn-close {
  box-shadow: none !important;
  opacity: 0.5;
  border-radius: 20px;
  filter: invert(1);
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat !important;
}

.left-div img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-left: -12px;
}

.pass-otp {
  padding: 40px 0px 10px 40px;
}

.Otp-btn {
  padding: 0px 22px;
}

.Otp-btn button {
  font-size: 18px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  color: #fff;
  background: #6366f1 !important;
  cursor: pointer;
  box-shadow: none !important;
  border: none;
  margin-top: 40px;
  margin: auto;
}

.Otp-btn button:hover {
  color: white;
  box-shadow: none !important;
}

.Otp-btn button:focus {
  background: #6366f1 !important;
}

.recaptcha-container {
  justify-content: center;
  margin-left: 3%;
  margin-bottom: 20px !important;
}

.otp-status a {
  color: #fff;
  cursor: pointer;
}

.otp-status {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
}

.error {
  text-align: center;
}

.modal-footer {
  justify-content: center;
}

.modal-content {
  box-sizing: border-box;
  border-radius: 15px !important;
}

.modal-backdrop.show {
  font-family: sans-serif;
}

.logo-img {
  width: 50px !important;
  margin-left: -7%;
  margin-top: -4px;
}

.user-box input::placeholder {
  color: grey;
}

.user-box input {
  background-color: #353535 !important;
  width: 80%;
  height: 70px;
  padding: 0px 0px 0px 10px;
  border: none;
  font-size: 19px;
  outline: none;
  color: #fff;
}
.user-box input:-webkit-autofill,
.user-box input:-webkit-autofill:hover,
.user-box input:-webkit-autofill:focus,
.user-box input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px rgb(53, 53, 53) inset !important;
  -webkit-background-color: #353535 !important;
  -webkit-text-fill-color: white !important;
  border: none !important;
  outline: none !important;
}

.email_input {
  width: 100% !important;
  background-color: #fff !important;
}

.email_input:focus {
  outline: none !important;
  box-shadow: none !important;
  color: #fff;
}

.swiper-modal .modal-header {
  border: none;
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 1;
  margin: auto;
}

.modal-footer {
  border: none;
}

.information p a {
  color: #fff;
}

.information p {
  color: #fff;
  line-height: 1.5;
  font-size: 12px;
  padding-top: 20px;
  text-align: center;
  padding: 20px 10px 0px 10px;
}

.modal-footer button span {
  position: absolute;
  display: block;
}

.PhoneInput {
  padding: 8px 15px;
  height: 70px;
  border-radius: 7px;
  background-color: #353535;
  color: #ffff;
}

.PhoneInputInput::placeholder {
  color: grey;
}

.tinder-baseline h3 {
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  font-family: "Golos Text";
  line-height: 38px;
  margin-right: 1em;
}

.tinder-baseline p {
  font-size: 14px;
  display: block;
  line-height: 1.5;
  font-weight: 400;
  margin: 12px 10em 0 0;
  color: rgba(255, 255, 255, 0.6);
}

.msg h6 {
  color: #fff;
  font-size: 13px;
  padding: 0px 10px 10px 10px;
}

.navbar button {
  background: #000;
  border: #6366f1 !important;
  border-style: solid !important;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: white !important;
  padding: 5px 30px;
  border: none;
  outline: 0 !important;
  box-shadow: none !important;
  width: 200px;
  padding: 10px 16px;
  border-radius: 7px;
}

.navbar button:hover {
  color: white !important;
  border: none;
  outline: 0 !important;
  background: #000 !important;
}

.navbar button:focus {
  background: #000 !important;
}

.for-app {
  padding: 34px 26px;
}

.PhoneInputCountrySelect {
  opacity: 0 !important;
}

.for-app button {
  font-size: 19px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  color: #fff;
  background: #6366f1 !important;
  cursor: pointer;
  box-shadow: none !important;
  border: none;
  margin: 0;
  width: 100%;
}

.enter-otp {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
  color: #ffff;
}

.for-app button:hover {
  background: #6366f1 !important;
  color: white;
  box-shadow: none !important;
}

.for-app button:focus {
  background: #6366f1 !important;
}

.logo img {
  width: 80px;
  height: 80px;
}

.logo {
  font-size: 32px;
  font-weight: 600;
  color: #fff !important;
  text-decoration: none;
}

.sectionOne {
  color: #424242;
}

.swipe-Heading {
  font-size: 59px;
  font-weight: 700;
  line-height: 61px;
  text-align: center;
  color: #02010c;
}

.swipe-sub-heading {
  color: #1e1e1e !important;
  text-align: center;
  margin-top: 25px;
  font-size: 22px;
  font-weight: 400;
}

.Auto-Liking-btn {
  padding: 12px 0 8px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  min-height: 60px;
}

.Auto-Liking-btn {
  margin-top: 20px;
  width: 50%;
  color: #333;
  text-decoration: none;
  text-align: center;
  height: 40px;
  line-height: 10px;
  padding: 0 20px;
  border: 0 solid #000;
  border-radius: 35px;
  font-size: 16px;
  outline: 0;
  cursor: pointer;
  background: #6366f1;
  color: #fff;
  font-weight: 700;
}

.payment-detail-head {
  color: #5c5c66;
}

.pay-btn {
  background: #6366f1;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  border-radius: 10px;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
}

.disabled-pay-btn {
  background: #6c757d;
  cursor: default !important;
}

.Auto-Liking-btn:hover {
  color: white;
}

.homepic img {
  width: 100%;
  height: 100%;
  margin-top: -5%;
  object-fit: contain;
}

.line-through {
  text-decoration: line-through;
}

@media (min-width: 1250px) {
  .its-free {
    margin-top: 2px;
    margin-left: 12%;
    font-size: 14px;
  }
}

.Second-Section {
  width: 100%;
  padding: 15px 70px;
  position: relative;
}

.it-work-heading {
  text-align: center;
}

.it-work-heading h2 {
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 35px;
  font-size: 45px;
  line-height: 55px;
}

.card-row {
  margin-bottom: 25px;
  text-align: center;
}

.Second-Section .cardImg {
  width: 75px;
  height: 75px;
  margin: auto;
}

.Second-Section .card {
  padding: 20px 0px 8px 0px;
  border-radius: 5px;
  height: 100%;
}

.Second-Section .card .card-text {
  color: #424242;
}

.Second-Section .it-work-cards {
  margin-top: 20px;
}

.Section-Four-Heading {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}

.likepic img {
  width: 100%;
  height: 100%;
}

.text-container {
  padding: 24px;
  color: white;
}

.text-container h1 {
  font-size: 32px;
}

.text-container,
.text-Secondcontainer p {
  margin-top: 18px;
  font-size: 16px;
}

.thred-Section {
  width: 100%;
  padding: 48px;
}

.text-Secondcontainer {
  padding: 24px;
  font-size: 16px;
  justify-content: center;
}

.text-Secondcontainer h1 {
  font-size: 32px;
  font-weight: 800;
}

.map img {
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding-left: 0px;
  padding-right: 0px;
}

.five-Section {
  width: 100%;
  padding: 48px;
}

.bumble img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 50%);
}

.swiper-section {
  padding-top: 7%;
}

.swiper-slide {
  text-align: center;
}

.swiper-slide img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.swiper-slide p {
  text-align: center;
  font-size: 18px;
}

.swiper-pagination {
  position: relative !important;
}

.footer {
  padding: 64px 24px;
  color: #fff;
  background: #14131e;
}

.footer-section img {
  margin-top: -25px;
  margin-left: 40%;
}

.links {
  margin-top: -6%;
  text-align: end;
}

.links a:hover {
  opacity: 0.8;
}

.footer-main a {
  color: white;
  font-size: 18px;
}

.footer-main li {
  display: inline-block;
  padding: 21px;
  font-size: 18px;
}

.footer-main li a {
  text-decoration: none;
}

.footer-main li a:hover {
  text-decoration: underline;
}

.footer-main span {
  margin-left: 45%;
  font-size: 18px;
}

.footer-btn {
  text-align: end;
  margin-top: -1%;
  font-size: 15px;
  color: white;
}

.footer-btn a {
  color: white;
}

.footer-btn a:hover {
  color: white;
}

.hide-form input {
  width: 66px !important;
  height: 67px !important;
  border-radius: 10px;
  background: transparent;
  font-size: 24px;
}

.hide-form input:last-child {
  margin-left: 0 !important;
}

.hide-form > div {
  display: flex !important;
  margin: auto !important;
  text-align: center !important;
  justify-content: center !important;
}

.right-arrow img {
  width: 1.5em;
  height: 1.5em;
}

.right-arrow {
  color: #000;
  position: absolute;
  left: 0;
  padding: 10px;
  opacity: 0.5;
  box-sizing: content-box;
}

.footer-content {
  line-height: 30px;
  font-size: 15px;
}

.footer-content a {
  text-decoration: none;
  color: white;
}

.inoidLink {
  color: #c2c2c2;
}

.modal-header a {
  border: none;
  opacity: 0.5;
}

.footer-content a:hover {
  text-decoration: underline;
}

.reviewSwiperPerson {
  margin-top: 15px !important;
}

@media (max-width: 767px) {
  .download_button {
    width: 260px !important;
    height: 46px !important;
    font-size: 17px !important;
    text-align: center !important;
    background-color: #652aeb !important;
    color: white !important;
    padding: 11px 20px !important;
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    display: inline-block !important;
    margin: 23px !important;
  }

  .hide-form input {
    width: 53px !important;
  }

  .ready-btn {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .step-circle,
  .step-circle-last {
    width: 20px !important;
    min-width: 20px !important;
    height: auto !important;
  }

  .sign-in img,
  .left-div img {
    display: none;
  }

  .swipe-Heading {
    font-size: 44px;
  }

  .swipe-sub-heading {
    font-size: 20px;
  }

  .Second-Section .card {
    margin-top: 20px;
  }

  .its-free {
    margin-top: 2px;
    text-align: center;
  }

  .Second-Section {
    background-size: 100% 56%;
  }

  .modal-title img {
    margin-top: 5px;
  }

  .hide-form > div {
    justify-content: center;
  }

  .hide-form > div input {
    margin-right: 16px;
  }

  .homepic img {
    padding-top: 0px !important;
  }

  .tinder-baseline h2 {
    font-size: 30px;
    line-height: 37px;
    text-align: center;
  }

  .inst-steps {
    margin-top: 50px !important;
  }

  .video-thumbnail img:nth-child(1) {
    width: 50px !important;
    height: 50px !important;
    position: absolute !important;
    left: 46% !important;
    top: 65% !important;
  }

  .question___search_bar_ {
    padding: 14px 23px !important;
  }

  .question___search_bar_::placeholder {
    font-size: 10px !important;
  }

  .search_icon_question_bank {
    right: 23% !important;
    top: 50% !important;
  }
}

@media (max-width: 480px) {
  .video-thumbnail img:nth-child(1) {
    width: 37px !important;
    height: 37px !important;
    position: absolute !important;
    left: 44% !important;
    top: 69% !important;
  }

  .hide-form input {
    height: 46px !important;
    width: 46px !important;
  }

  .download_button {
    width: 203px !important;
    height: 45px !important;
    font-size: 14px !important;
    text-align: center !important;
    background-color: #652aeb !important;
    color: white !important;
    padding: 11px 20px !important;
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    display: inline-block !important;
    margin: 10px !important;
    line-height: 16px !important;
  }

  .swipe-Heading {
    text-align: center;
  }

  .Second-Section .card .card-text {
    font-size: 14.5px !important;
  }

  .Second-Section .card {
    padding: 20px 0px 8px 0px !important;
  }

  .sectionOneHeadingCol h3 {
    font-size: 14px !important;
    margin: auto;
  }

  .sectionOneHeadingCol h3 img {
    width: 20px;
    margin-top: 6px;
  }

  .swipe-sub-heading {
    text-align: center;
  }

  .swipe-Heading {
    margin-bottom: 0px;
  }

  .underline {
    text-align: center;
    font-size: 14px !important;
    position: relative !important;
    left: -9% !important;
  }

  .underline div {
    width: 71px !important;
    border-top: 3px solid rgb(101, 42, 235) !important;
    position: absolute !important;
    top: 20px !important;
    left: 51% !important;
  }

  .tinder-baseline h2,
  .it-work-heading h2,
  #subsbtn {
    font-size: 31px !important;
    line-height: 38px !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  .signuptext {
    margin-bottom: 0px;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }

  #SecondsTags {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 33px;
    font-weight: 600;
    text-align: center;
  }

  .peragraph {
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: 500 !important;
    text-align: center !important;
  }

  .Second button {
    margin: auto;
  }

  .LetsDoIt {
    width: auto !important;
    height: 47px !important;
    margin-top: 30px !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    border-radius: 8px !important;
    font-size: 20px;
    align-self: flex-start;
    background-color: #6366f1;
    line-height: 0px !important;
    padding: 25px !important;
    font-size: 15px !important;
    margin-top: 10px !important;
  }

  .LetsDoItDiv {
    text-align: center;
  }

  .footer-div h4 {
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center !important;
  }

  .footer-div h3 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 26px !important;
    line-height: 33px !important;
  }

  .footer-div {
    padding: 16px 25px !important;
    border-radius: 19px !important;
  }

  .ready-btn {
    margin-top: 10px !important;
    width: 165px !important;
    font-size: 15px !important;
  }

  .sectionOneHeadingCol h3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .introSectionone,
  #sectiononesteps {
    font-size: 16px !important;
  }

  .homepic {
    padding-bottom: 15px !important;
  }

  .footer {
    padding: 0px 24px !important;
  }

  .for-app {
    padding: 30px !important;
  }

  .tinder-baseline h3 {
    font-size: 30px !important;
    margin-top: 10px !important;
  }

  .user-box img:nth-child(2) {
    margin-top: -18px;
  }

  .user-box p:nth-child(2) {
    margin-bottom: 0px !important;
    font-size: 15px !important;
  }

  .user-box p:nth-child(3) {
    margin-top: -10px;
  }

  .for-app button {
    background: #6366f1;
    border-radius: 12px;
    font-size: 23px;
    line-height: 35px;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .footer_info_line {
    text-align: center;
    font-size: 13px !important;
  }

  .footer_copyright_line {
    font-size: 14px !important;
  }

  .reverse-div {
    margin-top: 30px;
    flex-direction: column-reverse !important;
  }

  .homepic {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .Second-Section .card {
    margin-top: 20px;
  }

  .its-free {
    margin-top: 2px;
    text-align: center;
  }

  .Second-Section {
    background-size: 100% 56%;
  }

  .modal-title img {
    margin-top: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1250px) {
  .its-free {
    margin-top: 2px;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .pass-otp {
    padding: 20px;
  }

  #nav_btn {
    padding: 10px !important;
  }

  .tinder-baseline h3 {
    font-size: 25px !important;
    margin-top: 10px !important;
  }

  .Second-Section {
    padding: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .likepic img {
    width: 100%;
  }

  .text-container {
    padding: 0%;
  }

  .text-container h1 {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .footer-main li {
    padding: 10px 0px;
  }

  .text-Secondcontainer {
    padding: 0%;
    margin-top: 20px;
  }

  .thred-Section {
    padding: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .five-Section {
    padding: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .logo {
    font-size: 25px;
  }

  .logo img {
    width: 50px;
    height: 50px;
    margin-left: 0;
  }

  .footer-content {
    padding: 0;
    line-height: 33px;
  }

  .footered {
    padding: 0;
  }

  .footer {
    padding: 0px 14px;
  }

  .question___search_bar_ {
    padding: 14px 20px !important;
    width: 80% !important;
  }

  .search_icon_question_bank {
    right: 23% !important;
    top: 50% !important;
  }
}

@media (max-width: 850px) {
  .footer-main li {
    display: table;
  }

  .payment-modal {
    padding: 27px !important;
  }

  .footer-btn {
    text-align: left;
    margin-top: 5%;
  }

  .footer-content {
    padding: 20px 0;
  }

  .links {
    text-align: start;
    margin-top: 10px;
  }

  .three-section {
    flex-direction: column-reverse;
  }

  .five {
    flex-direction: column-reverse;
  }

  .swiper-modal .modal-body {
    padding: 0 !important;
  }

  .sign-in img {
    width: 100%;
    height: 100%;
  }

  .highlightImage {
    display: none !important;
  }

  .uploadImage {
    display: none !important;
  }

  .photosolveImage {
    display: none !important;
  }
}

.sign-in img {
  width: 100%;
  height: auto;
  margin-top: 10%;
  border-radius: 10px;
}

.left-div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 991px) {
  .left-div img {
    width: 100%;
    height: 100%;
    margin-left: 0 !important;
  }

  .sign-in img {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
  }

  .left-div {
    padding: 0px !important;
  }

  .Card img {
    padding-bottom: 40px !important;
  }

  .swiper-heading2 img {
    margin-left: 0;
  }

  .swiper-heading {
    margin-left: 0;
  }

  .card-col {
    margin-top: 20px;
  }

  .second-section {
    padding: 0 !important;
  }

  .tag-number {
    font-size: 45px !important;
    height: 45px !important;
  }
}

.footer-content h6 {
  text-align: end;
  margin-top: -41px;
}

.mobile-number img {
  cursor: pointer;
  filter: contrast(0);
}

.mobile-number img:hover {
  opacity: 0.7;
}

.mobile-number {
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.alert-danger {
  margin-bottom: 0px !important;
  background-color: #d16666;
}

/*extension page css start */

.swiper-heading title {
  text-align: center;
  justify-content: center;
  margin: auto;
}

.title img {
  margin-left: -20px;
}

.extenstion-login button {
  font-size: 25px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px !important;
  color: #e8d8d8;
  background: #6366f1 !important;
  cursor: pointer;
  box-shadow: none !important;
  border: none;
  margin: auto;
}

.extenstion-phone-input .PhoneInput {
  padding: 8px 12px;
}

.extenstion-information p {
  color: rgba(255, 255, 255, 0.6) !important;
  line-height: 1.5;
  font-size: 11px;
  padding-top: 20px;
  text-align: center;
}

.swiper-heading3 {
  text-align: center;
}

.swiper-heading3 span {
  font-size: 26px;
  color: #424242;
  font-weight: 600;
}

.payment-points {
  color: #5c5c66;
  line-height: 1.5;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.payment-card-details p {
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.payment-lines {
  color: #5c5c66;
  line-height: 1.5;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.subscription-heading {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
}

.subscription-heading2 {
  font-size: 30px;
  color: #fff;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
}

.pricing {
  font-weight: 600;
  font-size: 28px;
}

.ext-para {
  color: #fff;
  font-size: 14px;
}

/* extension page css end */

.border-left {
  padding-left: 10px;
}

.thank {
  font-weight: 600;
  font-size: 21px;
  text-align: center;
}

.inst-light-text {
  font-size: 14px;
  color: #534f63;
}

.font-weight-bold {
  font-weight: 600;
}

.inst-steps {
  font-size: 14px;
}

.inst-modal .modal-content {
  background-color: #14131e;
}

.step-circle {
  background: #6366f1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 10px;
  position: relative;
}

.step-circle::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 25px;
  background: #6366f1;
  top: 110%;
}

.step-circle-last {
  background: #6366f1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 10px;
}

.resub-button {
  background: #6366f1;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: white;
  padding: 5px 30px;
  border: none;
  outline: 0 !important;
  box-shadow: none !important;
}

.resub-button:hover {
  color: white;
  border: none;
  outline: 0 !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
  left: 0;
  width: 100%;
}

.sign-form {
  background: #000 !important;
  border-radius: 10px;
  color: #fff;
}

.footer-div {
  background: rgb(101, 42, 235);
  border-radius: 20px;
  padding: 40px 190px;
  border-radius: 19px;
  box-shadow: 0 0 20px 13px hsla(0, 0%, 100%, 0.05);
}

.ready-btn {
  width: auto;
  background-color: #14131e;
  height: 47px;
  margin-top: 30px;
  padding-right: 80px;
  padding-left: 80px;
  border-radius: 8px;
  font-size: 20px;
}

.main-home-pic {
  width: 100%;
  max-width: 765px;
  object-fit: contain;
  margin: auto;
}

.underline {
  position: relative;
  font-size: 30px;
  line-height: 35.55px;
  font-weight: 500;
}

.underline div {
  width: 100px;
  border-top: 5px solid rgb(101, 42, 235);
  position: absolute;
  top: 24px;
  left: 21px;
}

.hidden-form {
  background-color: #000;
}

.AnsAilogo {
  justify-content: center;
  font-size: 30px;
  transition: all 300ms ease;
}

#AiColor {
  color: #6366f1 !important;
}

.AnsAilogo:hover {
  transform: scale(1.07);
}

.topNavbar {
  margin-bottom: 0px !important;
  border-bottom: 0.5px solid #23232c;
  box-shadow: 6px -4px 9px 6px rgb(137 137 137 / 16%);
}

.sectionOneHeadingCol {
  flex-direction: column;
  display: flex;
}

.sectionOneHeadingCol span h1 {
  padding-top: 40px;
}

.homepic {
  padding-top: 40px;
  padding-right: 110px;
  padding-left: 100px;
  box-shadow: -9px 0px 7px -5px rgb(137 137 137 / 16%);
}

.sectionOneHeadingCol h3 {
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 35.55px;
  display: flex;
  align-items: flex-start;
}

.signuptext {
  font-size: 20px;
  color: #a5a4af;
}

.navbar {
  position: relative;
  margin: 20px 0;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.AnswerAilogo {
  padding-top: 32px;
  padding-bottom: 31px;
}

#SecondsTags {
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
}

.LetsDoIt {
  width: 187px !important;
  height: 47px;
  margin-top: 30px;
  padding-right: 80px;
  padding-left: 80px;
  border-radius: 8px;
  font-size: 20px;
  align-self: flex-start;
  background-color: #6366f1;
  transition: all 200ms ease;
  line-height: 0px;
  transition: all 200ms ease;
  padding: 30px;
}

#subsbtn {
  text-align: center;
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
}

.footered {
  padding-right: 220px;
  padding-left: 220px;
}

.peragraph {
  font-size: 20px;
  line-height: 30px;
  color: #a5a4af;
  font-weight: 500;
}

#AnswersAi {
  flex-grow: 1;
}

.login-btn {
  margin: 0 1em;
}

@media only screen and (max-width: 1440px) {
  .AnswerAilogo {
    font-size: 25px;
    line-height: 32px;
    letter-spacing: 0.25px;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .sectionOneHeadingCol span h1 {
    font-size: 30px;
    line-height: 37px;
  }

  .sectionOneHeadingCol {
    padding-right: 69px;
  }

  .sectionOneHeadingCol2 {
    padding-top: 67px !important;
    padding-left: 50px !important;
  }

  .sectionOneHeadingCol h3 {
    font-size: 25px;
    line-height: 32px;
    letter-spacing: 0.25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .swipe-sub-heading {
    font-size: 16px;
    line-height: 26px;
  }

  .homepic {
    padding-right: 100px;
    padding-left: 80px;
  }

  .mainText__ {
    line-height: 48px !important;
    font-size: 40px !important;
  }

  .tinder-baseline h2 {
    font-size: 36px;
    line-height: 46px;
  }

  .mainSmallText__ {
    margin-top: 20px !important;
    font-size: 19px !important;
  }

  #sec_btn {
    padding: 14px 15px !important;
    font-size: 14px !important;
    width: 170px !important;
  }

  .sectionOneHeadingCol {
    padding-right: 0px !important;
  }

  .tinder-baseline p,
  .signuptext,
  .peragraph {
    font-size: 16px;
    line-height: 26px;
  }

  .payment-button {
    height: 58px;
    font-size: 20px;
    line-height: 30px;
  }

  .it-work-heading h2 {
    font-size: 36px;
    line-height: 46px;
  }

  .Second-Section {
    padding-right: 50px;
    padding-left: 50px;
  }

  #SecondsTags {
    font-size: 30px;
    line-height: 37px;
  }

  .LetsDoIt {
    border-radius: 6px;
    font-size: 16px;
    height: 50px;
  }

  .footered {
    padding: 60px 100px;
  }

  .footer-div {
    padding: 30px 36px;
    border-radius: 19px;
  }
}

@media (max-width: 1250px) {
  .Auto-Liking-btn {
    width: 100%;
  }

  .third-sec-div br {
    display: none !important;
  }

  .Third-Section {
    padding: 0px 60px 15px 60px !important;
  }

  .left-side-margin {
    padding-right: 30px !important;
  }

  .homepic img {
    padding-top: 10%;
  }

  .footer-main span {
    margin-left: 35%;
  }

  .follow-us {
    margin-left: 20%;
  }

  .sign-in {
    max-width: unset;
    justify-content: center;
  }

  .footer-content h6 {
    text-align: start !important;
    margin-top: 0px !important;
  }

  .sectionOneHeadingCol2 {
    padding-top: 50px !important;
    padding-right: 0px !important;
  }

  .mainText__ {
    line-height: 48px !important;
    font-size: 35px !important;
    line-height: 43px !important;
  }

  .mainSmallText__ {
    margin-top: 15px !important;
    font-size: 15px !important;
    line-height: 28px !important;
  }

  #sec_btn {
    margin-top: 20px !important;
    padding: 12px 12px !important;
    font-size: 14px !important;
    width: 140px !important;
  }
}

@media only screen and (max-width: 991px) {
  .footered {
    padding: 30px 80px;
  }

  .navbar {
    margin-bottom: 1px !important;
  }

  .third-sec-div {
    padding-right: 0px !important;
  }

  .navbar button {
    font-size: 15px !important;
  }

  .nav-link {
    color: #fff !important;
  }

  .Third-Section {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .left-side-content {
    padding-left: 50px !important;
  }

  .third-sec-div {
    margin-bottom: 0px !important;
  }

  .third-sec-div br {
    display: none !important;
  }

  .mainText__ br {
    display: none;
  }

  .mainSmallText__ br {
    display: none;
  }

  .mainText__ {
    text-align: center;
  }

  .mainSmallText__ {
    text-align: center;
  }

  .sectionOneHeadingCol2 {
    padding: 30px 50px 0px 50px !important;
    text-align: center;
  }

  .sectionOneHeadingCol {
    padding: 0px 6px 0px 6px;
  }

  .sectionOneHeadingCol {
    border-right: none;
    box-shadow: none;
  }

  .homepic {
    box-shadow: none;
  }

  .section_1 {
    flex-direction: column-reverse;
  }

  .login-btn {
    position: absolute !important;
    bottom: -55px !important;
    right: 0 !important;
  }

  .AnswerAilogo {
    padding-bottom: 40px;
  }

  .navbar button {
    font-size: 12px;
    width: auto;
  }

  #sec_btn {
    width: 200px !important;
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    display: block;
  }

  .Third-Section {
    padding: 50px !important;
  }

  .left-side-section {
    flex-direction: column-reverse !important;
  }

  .section-3-div > div {
    display: flex;
    flex-direction: column;
  }

  .third-sec-div {
    padding-top: 10px !important;
  }

  .third-section-2 {
    padding: 0px !important;
  }

  .Third-Section {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .eight_container-fluid__ {
    padding: 50px 0px !important;
  }

  .payment_container {
    margin-top: 50px !important;
    padding: 20px !important;
  }

  .Price___ {
    font-size: 25px !important;
  }

  .price_description__ {
    font-size: 14px !important;
  }

  .purchase_button button {
    padding: 3px 0px !important;
    font-size: 15px !important;
  }

  .options_mode_1 {
    font-size: 13px !important;
  }

  #AnswersAi {
    flex-grow: unset !important;
    margin-left: 30px !important;
  }

  .sectionOneHeadingCol2 {
    padding: 30px !important;
  }

  .AnswerAilogo {
    font-size: 20px;
  }

  .swipe-sub-heading {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }

  .sectionOneHeadingCol span h1 {
    font-size: 26px;
  }

  .sectionOneHeadingCol h3 {
    font-size: 20px;
  }

  #subsbtn {
    font-size: 30px;
    margin-bottom: 20px !important;
  }

  .homepic {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .introSectionone {
    font-size: 20px;
  }

  .it-work-heading h2 {
    font-size: 30px;
  }

  .Second-Section {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 0px;
  }

  .tag-number {
    font-size: 30px !important;
    height: 30px !important;
  }

  .tinder-baseline p,
  .signuptext,
  .peragraph {
    font-size: 14px;
  }

  #SecondsTags {
    font-size: 26px;
  }

  .mainText__ {
    line-height: 48px !important;
    font-size: 28px !important;
    line-height: 38px !important;
  }

  .mainSmallText__ {
    margin-top: 15px !important;
    font-size: 14px !important;
    line-height: 25px !important;
  }

  .sectionOneHeadingCol2 {
    padding: 30px 50px 0px 50px !important;
  }

  .footer {
    margin-top: 0px;
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .AnsAilogo {
    font-size: 20px !important;
  }

  #AnswersAi {
    margin-left: 0px !important;
  }

  .login-btn {
    position: absolute;
    right: 17px;
  }

  .it-work-heading h2 {
    margin-bottom: 20px !important;
  }

  .footer {
    padding: 20px 24px;
  }
}

@media (max-width: 575px) {
  .payment_section__ {
    padding: 25px !important;
  }
}

@media (max-width: 490px) {
  .video-thumbnail img:nth-child(1) {
    width: 37px !important;
    height: 37px !important;
    position: absolute !important;
    left: 44% !important;
    top: 69% !important;
  }

  .hide-form input {
    height: 46px !important;
    width: 46px !important;
  }

  .download_button {
    width: 203px !important;
    height: 45px !important;
    font-size: 14px !important;
    text-align: center !important;
    background-color: #652aeb !important;
    color: white !important;
    padding: 11px 20px !important;
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    display: inline-block !important;
    margin: 10px !important;
    line-height: 16px !important;
  }

  .swipe-Heading {
    text-align: center;
  }

  .Second-Section .card .card-text {
    font-size: 14.5px !important;
  }

  .Second-Section .card {
    padding: 20px 0px 8px 0px !important;
  }

  .sectionOneHeadingCol h3 {
    font-size: 14px !important;
    margin: auto;
  }

  .sectionOneHeadingCol h3 img {
    width: 20px;
    margin-top: 6px;
  }

  .swipe-sub-heading {
    text-align: center;
  }

  .swipe-Heading {
    margin-bottom: 0px;
  }

  .underline {
    text-align: center;
    font-size: 14px !important;
    position: relative !important;
    left: -9% !important;
  }

  .underline div {
    width: 71px !important;
    border-top: 3px solid rgb(101, 42, 235) !important;
    position: absolute !important;
    top: 20px !important;
    left: 51% !important;
  }

  .tinder-baseline h2,
  .it-work-heading h2,
  #subsbtn {
    font-size: 31px !important;
    line-height: 38px !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  .signuptext {
    margin-bottom: 0px;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }

  #SecondsTags {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 33px;
    font-weight: 600;
    text-align: center;
  }

  .peragraph {
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: 500 !important;
    text-align: center !important;
  }

  .Second button {
    margin: auto;
  }

  .LetsDoIt {
    width: auto !important;
    height: 47px !important;
    margin-top: 30px !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    border-radius: 8px !important;
    font-size: 20px;
    align-self: flex-start;
    background-color: #6366f1;
    line-height: 0px !important;
    padding: 25px !important;
    font-size: 15px !important;
    margin-top: 10px !important;
  }

  .LetsDoItDiv {
    text-align: center;
  }

  .footer-div h4 {
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center !important;
  }

  .footer-div h3 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 26px !important;
    line-height: 33px !important;
  }

  .footer-div {
    padding: 16px 25px !important;
    border-radius: 19px !important;
  }

  .ready-btn {
    margin-top: 10px !important;
    width: 165px !important;
    font-size: 15px !important;
  }

  .sectionOneHeadingCol h3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .introSectionone,
  #sectiononesteps {
    font-size: 16px !important;
  }

  .homepic {
    padding-bottom: 15px !important;
  }

  .footer {
    padding: 0px 24px !important;
  }

  .for-app {
    padding: 30px !important;
  }

  .tinder-baseline h3 {
    font-size: 30px !important;
    margin-top: 10px !important;
  }

  .user-box img:nth-child(2) {
    margin-top: -18px;
  }

  .user-box p:nth-child(2) {
    margin-bottom: 0px !important;
    font-size: 15px !important;
  }

  .user-box p:nth-child(3) {
    margin-top: -10px;
  }

  .for-app button {
    background: #6366f1;
    border-radius: 12px;
    font-size: 23px;
    line-height: 35px;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .footer_info_line {
    text-align: center;
    font-size: 13px !important;
  }

  .footer_copyright_line {
    font-size: 14px !important;
  }

  .reverse-div {
    margin-top: 30px;
    flex-direction: column-reverse !important;
  }

  .homepic {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .sectionOneHeadingCol2 {
    padding: 30px 20px 0px 20px !important;
  }

  .mainText__ {
    font-size: 24px !important;
    line-height: 35px !important;
  }

  .mainSmallText__ {
    font-size: 13px !important;
    line-height: 24px !important;
  }
}

div[role="status"] {
  color: #000 !important;
}

#introAi {
  color: #652aeb !important;
}

#introAia {
  color: #fff !important;
}

#introtitile {
  font-weight: 700;
  border-bottom: none;
}

#introheader {
  border-bottom: none;
}

.introSectionone a {
  text-align: center;
  text-decoration: none !important;
  color: #fff !important;
  word-wrap: break-word;
}

#sectiononesteps {
  font-size: 30px;
  font-weight: 500;
}

.rightSideP {
  font-size: 30px !important;
}

.btn-primary {
  border: none !important;
  box-shadow: none !important;
}

.download_button {
  width: 310px;
  height: 56px;
  margin: 50px 0px;
  font-size: 20px;
  text-align: center;
  background-color: #652aeb;
  color: white;
  padding: 12px 30px !important;
  border: none;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 6px;
  display: inline-block;
}

.video-thumbnail {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.video-thumbnail img:nth-child(1) {
  margin-top: -60px;
  width: 100%;
  height: 100%;
}

.video-thumbnail img:nth-child(1) {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 47%;
  top: 57%;
}

.video-thumbnail img:nth-child(2) {
  width: 100%;
}

.modal-header {
  border: none !important;
  margin: 1.5em 1em;
}

.AnswerAi_Main_IntroVideo {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: block;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0);
  object-position: 50% 50%;
}

.highlightImage {
  position: absolute;
  right: -190px;
  top: 40%;
}

.photosolveImage {
  position: absolute;
  right: -194px;
}

.uploadImage {
  position: absolute;
  transform: rotate(-0.304deg);
  left: -194px;
  top: 10%;
}

.AnswerAi_Main_IntroVideo {
  box-shadow: rgba(10, 0, 31, 0.1) 2px 1px 24px 8px;
}

@media (min-width: 850px) and (max-width: 1499px) {
  .highlightImage {
    right: -144px;
  }

  .photosolveImage {
    right: -174px;
  }

  .uploadImage {
    left: -168px;
  }
}

@media (min-width: 1200px) and (max-width: 1329px) {
  .highlightImage {
    top: 34%;
  }

  .photosolveImage {
    top: 3%;
  }

  .uploadImage {
    top: 3%;
  }
}

@media (min-width: 850px) and (max-width: 1199px) {
  .highlightImage {
    top: 20%;
  }

  .photosolveImage {
    top: -8%;
  }

  .uploadImage {
    top: -8%;
  }
}

@media (max-width: 1200px) {
  .AnswerAi_Main_IntroVideo {
    box-shadow: rgba(10, 0, 31, 0.1) 2px 1px 12px 8px;
  }
}

.mainText__ {
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
}

.sectionOne {
  background-color: #6366f1 !important;
}

.mainSmallText__ {
  color: rgba(255, 255, 255, 0.8);
  font-family: Stolzl;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32.5px;
  margin-top: 35px;
}

#sec_btn {
  background-color: #000 !important;
  margin-top: 35px;
}

.sectionOneHeadingCol {
  padding-left: 0;
}

.sectionOneHeadingCol2 {
  padding-top: 90px;
  padding-left: 115px;
}

.sec-8-swipe-Heading {
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.sec-8-swipe-sub-heading {
  color: rgba(216, 228, 238, 0.8) !important;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 0px !important;
}

.eight_container-fluid__ {
  background: #050b1a;
  padding: 90px 0px;
}

.payment_container {
  padding: 30px;
  background: linear-gradient(180deg, #6366f1 0%, #5477c5 100%);
  border-radius: 25px;
  color: #fff;
  margin-top: 60px;
}

.Price___ {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.Price_month__ {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.price_description__ {
  padding-top: 6px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.purchase_button button {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 5px 0px;
  margin-top: 12px;
}

.Cancel_guide {
  margin-top: 13px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.options_mode_1 {
  font-size: 15px;
  font-style: normal;
  line-height: 31.325px;
}

.navbar-light .navbar-toggler-icon {
  filter: contrast(0.1) !important;
}

.privacy_policy_haedings__ {
  font-weight: 700;
  font-size: 19px;
}

.terms_headig____ {
  margin-bottom: 1rem;
  color: #000;
  text-decoration: none;
}

.terms_headig____:hover {
  color: #000;
}

.logo-container {
  overflow: hidden;
  white-space: nowrap;
}

@keyframes logo-slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 600px) {
  .logo-container {
    display: flex;
    overflow-x: auto;
  }
}

@keyframes logo-slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.logo-container {
  overflow: hidden;
  white-space: nowrap;
}

.logo-container img {
  animation: logo-slide 10s linear infinite;
}

/* Example of delay for continuous effect */
.logo-container img:nth-child(1) {
  animation-delay: 2s;
}

.logo-container img:nth-child(2) {
  animation-delay: 2s;
}

.logo-container img:nth-child(3) {
  animation-delay: 2s;
}

.logo-container img:nth-child(4) {
  animation-delay: 2s;
}

.logo-container img:nth-child(5) {
  animation-delay: 2s;
}

.princing_plan_heading__ {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.princing_plan_sub_heading__ {
  color: rgba(255, 255, 255, 0.6);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 7px;
}

.select_princing_tabs__ {
  margin-top: 20px !important;
  display: flex;
  width: fit-content;
  margin: auto;
  background: #6366f1;
  padding: 6px;
  border-radius: 35px;
  color: #fff;
  justify-content: space-between;
  cursor: pointer;
}

.plan_active {
  background: black;
  border-radius: 30px;
}

.select_princing_tabs__ li {
  font-size: 13px;
  padding: 8px 16px !important;
}

.select_princing_tabs__ li a:hover {
  color: #fff;
}

.main_princing_plan_container {
  border: 2px solid #6366f1;
  background: linear-gradient(
    157deg,
    rgba(99, 102, 241, 0.4) 0%,
    rgba(99, 102, 241, 0) 86.56%
  );
  border-radius: 20px;
  padding-top: 20px !important;
  padding-bottom: 15px !important;
}

.main_princing_plan_container1 {
  border: 2px solid #6366f1;
  background: linear-gradient(
    157deg,
    rgba(99, 102, 241, 0.4) 0%,
    rgba(99, 102, 241, 0) 86.56%
  );
  border-radius: 20px;
  padding-bottom: 15px !important;
  padding-left: 0;
  padding-right: 0;
}

.princing_purchase_ {
  font-size: 14px;
}

.dollor__price_ {
  font-size: 40px;
}

.plan_price {
  margin-top: -30px;
}

.purchase_plan_button button {
  width: 95%;
  display: flex;
  background: #6366f1;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: auto;
}

.information_checkmark {
  font-size: 11px;
  color: rgb(99, 102, 241);
}

.information_checkmark_display {
  font-size: 18px;
  color: rgb(99, 102, 241);
}

.complete_flow_lines {
  display: flex;
  gap: 20px;
}

.essay_mode_explain_,
.talk_book_explain_,
.leacture_explain_ {
  background-color: #0000;
  color: #fff;
  padding: 17px 10px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid grey;
}

.essay_board_heading___ {
  font-size: 15px;
}

.essay_board_subheading___ {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 16px;
}

.essaysubmenus__ {
  margin-top: 5px;
  font-size: 11px;
}

.slick-dots li button:before {
  color: #fff !important;
}

.next_skip_button__ {
  width: fit-content;
  margin-top: 20px;
  background: transparent;
  color: #fff;
}

.slick-dots {
  display: inline-grid !important;
  bottom: inherit !important;
  left: 100% !important;
  top: 35% !important;
  width: auto !important;
}

.custom-slide {
  height: 350px;
}

.custom-slide img {
  height: 100%;
}

.modal-content {
  background-color: #000 !important;
  color: #fff !important;
  border: 2px solid #fff;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.show_video_frame {
  border: 1px solid #6366f1;
}

.activeExplain {
  background-color: #353535;
}

#paymentModal .modal-content {
  border: none;
}

.discription_explain {
  font-size: 14px;
  padding: 14px 19px 10px 24px;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}

.desktop-button,
.mobile-button {
  width: 90%;
}

.answerAi_subscription__ {
  border: 2px solid #6366f1;
  border-radius: 20px;
}

.answerAi_subscription__ {
  background: linear-gradient(
    110deg,
    rgba(99, 102, 241, 0.3) -1.32%,
    rgba(18, 18, 18, 0) 78.3%
  );
}

.slick-slide img {
  width: 100% !important;
}

/* Add more nth-child rules as per the number of logos */

.edit_number-icon__ {
  width: 20px;
  height: 20px;
  margin-top: 6px;
  margin-left: 10px;
}

#paymentModal .modal-content {
  border-radius: 20px !important;
}

.css-xl7lxk-control {
  border: none !important;
}

.css-xl7lxk-control::placeholder {
  color: grey;
}

#ques-email-inp {
  color: #fff !important;
}

.pricing_discount {
  padding-left: 32px;
}

#video-component {
  overflow-y: scroll;
}

.pricing_modal_custom > a:hover {
  color: #fff;
}

.body-loader {
  overflow: hidden;
  padding-right: 17px;
}

@media (max-width: 639px) {
  .information_checkmark_display {
    font-size: 12px;
  }
  .discount_image {
    width: 130px;
  }
}

@media (max-width: 503px) {
  .information_checkmark {
    font-size: 8px;
  }
  .dollor__price_ {
    font-size: 34px !important;
    margin-top: 0.725rem !important;
  }
  .dollar-sign {
    font-size: 1.25rem !important;
    margin-top: 0.875rem !important;
  }
}

@media (max-width: 455px) {
  .information_checkmark_display {
    font-size: 10px;
  }
  .discount_image {
    width: 100px;
  }
}

@media (max-width: 407px) {
  .information_checkmark_display {
    font-size: 8px;
  }
  .information_checkmark {
    font-size: 6px;
  }
  .dollor__price_ {
    font-size: 26px !important;
  }
  .base_price {
    font-size: 16px !important;
  }
}

.flash-card-component {
  padding: 70px !important;
}

.prose {
  max-width: 90ch !important;
  width: 100% !important;
}

.typing-text {
  margin-right: 30px !important;
}

.main_Question_bank_ {
  background-color: black;
  padding: 65px 0px;
}

.Question_bank_heading__ {
  text-align: center;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 121.429%;
}

.question_bank_subheading__ {
  color: rgba(238, 238, 238, 0.6);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 24px;
}

.question_bank_searchbar__ {
  position: absolute;
  width: 100%;
  padding-top: 30px;
}
.question___search_bar_ {
  padding: 15px 50px;
  width: 70%;
  height: 70px;
  border-radius: 60px;
  border: 2px solid #6366f1;
  background: #000;
  color: #fff !important;
  box-shadow: 0px -10px 40px 8px rgba(99, 102, 241, 0.1);
}

.question___search_bar_:focus-visible {
  outline: none;
}

.second_Question_bank_ {
  background-color: #121212;
  padding: 50px 0px;
  min-height: 61vh;
}

.search_icon_question_bank {
  position: absolute;
  right: 21%;
  top: 48%;
  width: fit-content;
  height: auto;
  padding: 0;
}

.search_icon_question_bank svg {
  position: absolute;
  color: #fff;
  font-size: 30px;
}

.second_Question_bank_buttons button {
  width: auto;
}

.second_Question_bank_buttons button {
  cursor: pointer;
  padding: 10px 15px;
  border: 1px solid rgba(238, 238, 238, 0.2);
  background-color: transparent;
  border-radius: 5px 5px 5px 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 12px;
  color: rgba(238, 238, 238, 0.4);
}

.second_Question_bank_buttons button.active {
  background-color: rgba(99, 102, 241, 0.16);
  color: #fff;
}

.second_Question_bank_buttons {
  border-top: none;
  padding: 20px;
  justify-content: center;
}

.second_Question_bank_subject {
  border: 1px solid rgba(238, 238, 238, 0.2);
  width: fit-content;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 6px;
  color: rgba(238, 238, 238, 0.8);
}

.share_question_body {
  overflow: hidden;
  color: #eee;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-top: 20px;
  height: 92px;
}

.footer_div_share_question {
  background-color: #121212;
  margin-bottom: -1px;
  border-radius: 8px 8px 0px 0px !important;
  position: relative;
  height: 67%;
}

.footer_div_share_question2 {
  background-color: #121212;
  margin-bottom: -1px;
  border-radius: 8px !important;
  position: relative;
}

.second_question_explanation_div {
  padding: 17px 17px 0px 17px !important;
  border-radius: 8px;
}

.Explanation_text {
  font-size: 14px;
}

.explantion_button_ {
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid var(--desktop-dark-purple-default, #6366f1);
  opacity: 0.8;
  background: var(--desktop-dark-purple-opacity16, rgba(99, 102, 241, 0.16));
}

.explantion_button_ {
  padding: 10px;
}

.explanation_button_ {
  font-size: 14px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #6366f1;
  opacity: 0.8;
  background: var(--desktop-dark-purple-opacity16, rgba(99, 102, 241, 0.16));
}

.share_question_subheading {
  color: rgba(238, 238, 238, 0.8) !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.blurLock_lock {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blurLock_lock_heading {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#showFullQuestion {
  padding-top: 100px !important;
  padding-bottom: 0px !important;
}

.showHeading {
  color: #eee;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 50px;
}

.subscribe_button {
  width: 180px;
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.question_bank_view_more {
  width: 200px;
  border-radius: 12px !important;
  background: rgba(238, 238, 238, 0.06) !important;
  padding: 10px 0px;
}

.input-placeholder::placeholder {
  color: rgba(238, 238, 238, 0.4);
  font-size: 13px;
}

.question_bank_loader {
  justify-content: center;
  display: flex;
}

.copy_share_chat_link {
  width: fit-content !important;
  background-color: none !important;
  border: 1px solid grey !important;
}

.copy_share_chat_link:hover {
  background: none !important;
}

.question_view_empty_data {
  width: 200px;
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  pointer-events: none;
  padding: 10px 0px;
}

.PhoneInputCountrySelect option {
  color: #000 !important;
}

#wrapper {
  background: #fff;
  overflow: hidden;
  transition: height 200ms;
  position: absolute;
  padding: 10px;
  width: -webkit-fill-available;
  border: 1px solid black;
}

.notOpen {
  height: 0px !important;
}

#list li {
  cursor: pointer;
}

.ElementsApp.is-autofilled .InputElement {
  -webkit-text-fill-color: white !important;
  caret-color: white !important;
}
