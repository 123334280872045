@import url("https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;600;700;800;900&display=swap");

.FormGroup {
  padding: 0;
  border-style: none;
  will-change: opacity, transform;
  border-radius: 4px;
  color: #fff;
}
.custom_banner_main {
  background: linear-gradient(to right, #512da8 0%, #8e24aa 50%, #6200ea 100%);
}
.banner_font_custom {
  font-size: 15px;
}
.CardNumberField-input-wrapper > input {
  color: #fff !important;
}
.FormRow label {
  font-size: 15px;
  color: #fff;
  display: revert !important;
}
/* Base styles for the input */
.FormRow input {
  background-color: #353535;
  border: none !important;
  color: #fff;
  width: 100%;
  padding: 18px;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
}

/* Autofill styles */
.FormRow input:-webkit-autofill,
.FormRow input:-webkit-autofill:hover,
.FormRow input:-webkit-autofill:focus,
.FormRow input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(53, 53, 53) inset !important;
  -webkit-background-color: #353535 !important;
  -webkit-text-fill-color: white !important;
  border: none !important;
  outline: none !important;
}

.FormRow input:focus {
  outline: none !important;
  background-color: #353535 !important;
}

.ElementsApp input:-webkit-autofill {
  -webkit-animation: none;
}

.ElementsApp .InputElement.is-invalid {
  color: #fff !important;
}
.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  background-color: transparent;
  color: black;
}

.ElementsApp,
.ElementsApp .InputElement {
  color: #fff;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 10px;
  border-radius: 7px;
}

.InputContainer .InputElement {
  font-size: 18px;
  color: #fff !important;
}

#stripe-card-element.StripeElement.empty {
  color: #fff !important;
}

#stripe-exp-element.StripeElement.empty {
  color: #fff !important;
}

#stripe-cvc-element.StripeElement.empty {
  color: #fff !important;
}

.ElementsApp input {
  color: #fff !important;
}

.mycard {
  margin-top: 10px;
  color: #000 !important;
  padding: 18px;
  background-color: #353535 !important;
}

button {
  width: 100%;
  text-align: center;
  background-color: #6366f1;
  color: white;
  padding: 5px 30px;
  border: none;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 6px;
}

.btn-primary:focus,
.btn-primary:hover {
  background: #652aeb !important;
}

.payment-button:hover {
  box-shadow: none;
  background: #652aeb !important;
  border: none;
}

.expiry-date,
.cvc-number {
  width: 98%;
  padding: 18px;
  background-color: #353535 !important;
  margin-top: 15px;
}

.name {
  color: #8e8e93;
  margin-left: 16px;
  font-size: 15px;
}

.card {
  position: relative;
}

.visa-img {
  position: absolute;
  right: 16px;
  top: 30px;
}

.card {
  border-radius: 10px;
}

.card-body {
  padding: 13px;
}

.card-item-number-label {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 21px;
  margin-bottom: 35px;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}

.card-item-number {
  width: 15px;
  display: inline-block;
}

.card-item-number.-active {
  width: 24px;
}

.card-contact {
  color: #fff;
  display: flex;
  align-items: flex-start;
}

.card-item-info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 10px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}

.card-date {
  flex-wrap: wrap;
  font-size: 16px;
  margin-left: auto;
  padding: 10px;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}

.card-item-date {
  opacity: 0.7;
  font-size: 13px;
  padding-bottom: 6px;
  width: 100%;
}

.card-item-time {
  position: relative;
}

.card-item-time span {
  width: 22px;
  display: inline-block;
}

.Card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.payment-modal {
  padding: 30px;
}

.payment-modal .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}

/* essay board border */

.border_essay_container {
  border: 1px solid #e3e3e3;
  border-radius: 17px;
}

.straightA_s {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.checkmark-img {
  width: 30px;
}

.success-text {
  color: #67baa9;
  font-weight: 600;
  margin-top: 10px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.modal-content {
  position: relative;
  background-color: #f4f5f9;
}

.ring-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #6564684d;
  z-index: 2;
  justify-content: center;
}

.ring-loader span {
  width: 125px !important;
  height: 125px !important;
}

.payment-form-error {
  color: red;
}

.cancel-btn {
  white-space: nowrap;
}

.cancel-modal .btn {
  width: auto;
}

.google-apple-pay-btn .StripeElement {
  padding: 0px;
  margin-top: 10px;
  border: 0px;
}

.progress {
  height: 12px !important;
  width: 100% !important;
  left: 0 !important;
  border-radius: 10.5px !important;
  background-image: linear-gradient(
    90deg,
    rgba(220, 221, 225, 1) 0%,
    rgba(220, 221, 225, 1) 100%
  ) !important;
}

.progress-bar {
  background-color: #6366f1;
}

.card {
  background-color: #14131e;
}

.refresh_getting {
  padding: 15%;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.Add_promotion_code_Apply {
  color: #6366f1 !important;
  cursor: pointer;
}

.stripe_checkout_coupon {
  position: relative;
}

#stripe_coupon_apply_button {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: #6366f1;
}

#stripe_coupon {
  background-color: #fff;
  border: 1px solid #7e68be;
  color: #fff;
}

.stripe_checkout_coupon > input:focus {
  outline: none !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

.main_btn_stripe_coupon div[role="status"] {
  position: absolute;
  top: 8px;
  right: 10px;
}

.stripe_promo_tag {
  border: 1px solid;
  padding: 7px;
  background: #6366f1;
  color: black !important;
  border: none;
  border-radius: 4px;
}

.stripe_promo_tag > svg:nth-child(1) {
  opacity: 0.8;
  fill: #fff;
}

.stripe_promo_tag > svg:nth-child(3) {
  stroke-width: 1px;
  height: 12px;
  opacity: 0.7;
  cursor: pointer;
  fill: #fff;
}

.stripe_promo_tag span {
  color: #fff !important;
  opacity: 0.8;
  padding: 0px 5px;
}

.stripe_checkout_coupon > input:-webkit-autofill,
.stripe_checkout_coupon > input:-webkit-autofill:hover,
.stripe_checkout_coupon > input:-webkit-autofill:focus,
.stripe_checkout_coupon > input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(20, 19, 30) inset !important;
  -webkit-background-color: #14131e !important;
  -webkit-text-fill-color: white !important;
}

.privacy_policy_heading {
  font-size: 30px;
  font-weight: 700;
  cursor: pointer;
}

.privacy_ai_color__ {
  color: #6366f1 !important;
}

/* --------------------------------------------------------------------------------------- */

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-items {
  text-align: end;
  position: relative;
}

@media (min-width: 992px) {
  .answer-ai-logo {
    position: absolute;
    top: 35px;
  }
}

.answer-ai-logo {
  cursor: pointer;
}

.nav-link {
  font-weight: 400;
  margin: 0 2rem 0 0;
  color: white !important;
}

#nav_btn {
  background: #02010c !important;
  border: #6366f1;
  border-style: solid;
  padding: 12px 31px;
  border-radius: 7px;
  width: fit-content;
}

.section_1 {
  justify-content: center;
}

.section_1_div {
  text-align: center;
  display: flex;
  justify-content: center;
}

.section_1_div_in {
  border: 1px solid #e0e7ff;
  background: #e0e7ff;
  border-radius: 9px;
  width: fit-content;
  padding: 4px 1.5em;
}

.section_1_span {
  color: #6366f1;
  font-size: 14px;
  font-weight: 700;
}

.btn_div {
  display: flex;
  justify-content: center;
  margin-top: 34px;
}

#sec_btn {
  z-index: 99;
  background: #6366f1;
  padding: 9px 0px;
  border-radius: 7px;
  width: -moz-fit-content;
  color: white;
  font-weight: 700;
  font-size: 20px;
  width: 200px;
}

.second-section {
  display: flex;
  justify-content: space-between;
  padding: 32px 84px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.second-section-div {
  position: relative;
}

.tik-tok-logo {
  border: 1px solid #3478f6;
  background: #3478f6;
  padding: 0.4em;
  border-radius: 20px;
  height: 30px;
}

.Third-Section {
  width: 100%;
  background-color: #0a152f;
  padding-top: 144px;
  padding-bottom: 192px;
}

.third-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

@media (min-width: 280px) and (max-width: 991px) {
  .Third-Section {
    padding: 2em !important;
  }

  .essay-container {
    margin-top: 10px !important;
  }

  .essay-tool-txt {
    font-size: 30px !important;
  }

  .essay-board-length {
    padding-bottom: 2em;
  }

  .essay-container-col {
    padding: 0 2em;
  }
}

.tik-tok-content {
  margin: 0.1em 0.5em;
  font-size: 19px;
  font-weight: 500;
}

.third-section-btn {
  margin: 25px 25px 0 0;
}

.third-section-video-content {
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
}

.third-section-2 {
  margin-top: 22px;
}

.third-section-1 {
  display: flex;
  width: 100%;
  align-items: center;
}

@media (min-width: 1100px) {
  .third-section-div {
    padding: 2em;
  }
}

.third-sec-h1 {
  color: #aae9ff;
  font-weight: bold;
  font-size: 36px;
}

.third-sec-div {
  color: rgb(175, 186, 199);
  font-size: 18px;
  font-weight: bold;
  line-height: 29px;
  padding-right: 165px;
  padding-top: 30px;
}

.sec-img {
  width: 100%;
  object-fit: contain;
  margin-top: -65px;
}

.Fourth-Section {
  margin-top: 135px;
  margin-bottom: 135px;
}

.fourth-section {
  justify-content: center;
}

.fourth-sec-heading {
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
  color: #1f2937;
  padding-top: 21px !important;
}

.photo-block {
  justify-content: center;
  flex-wrap: wrap;
}

.p-block {
  margin: 17.5px 16px;
}

.p-block-heading {
  margin-top: 20px;
  padding-left: 0.7em;
  font-size: 26px;
  font-weight: 600;
}

.p-block-div {
  padding-left: 1em;
  font-size: 20px;
  margin-top: 15px;
}

@media (min-width: 575px) {
  .p-block-div {
    width: 23em;
  }
}

.fourth-sec-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.section-5 {
  justify-content: center;
}

#sec-5-div-in {
  padding: 4px 0.5em;
}

#sec-5-heading {
  text-align: start;
  font-size: 42px;
  line-height: 52px;
  margin-top: 14px;
}

#sec-5-sub-heading {
  font-weight: 400;
  font-size: 16px;
  text-align: start;
  margin-bottom: 35px;
  margin-top: 7px;
  line-height: 23px;
}

.section-five-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1.2em;
}

@media (min-width: 1400px) {
  #sec-5-sub-heading {
    padding-right: 6em;
  }
}

#sec-6-div-in {
  padding: 4px 0.5em;
  border-radius: 20px;
}

#sec-6-heading {
  text-align: start;
  font-size: 42px;
  line-height: 52px;
  margin-top: 10px;
}

#sec-6-sub-heading {
  font-weight: 400;
  font-size: 16px;
  text-align: start;
  margin-bottom: 24px;
  margin-top: 7px;
  width: 26em;
  color: #6b7280 !important;
  line-height: 23px;
}

#sec-6-col1 {
  background: #eef2ff;
  height: 589px;
  width: 50%;
  justify-content: center;
  align-items: center;
  border-radius: 30px 0 0 30px;
  padding: 0 8em;
}

#sec-6-col2 {
  background: #a5b4fc;
  width: 50%;
  border-radius: 0 30px 30px 0;
  padding: 70px 150px 0 150px;
  position: relative;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  #sec-6-col2 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .sec-6-crown {
    left: 4em !important;
  }

  .clear_summary_input {
    right: 11% !important;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .new_history_btn {
    width: 39% !important;
    font-size: 16px !important;
    padding: 5px 10px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #sec-6-col2 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .sec-6-crown {
    left: 2em !important;
  }

  .clear_summary_input {
    right: 13% !important;
  }
}

@media (max-width: 991px) {
  #sec-6-col1 {
    width: auto !important;
    border-radius: 30px;
    margin-bottom: 2em;
  }

  .complete_flow_lines div {
    width: 100px !important;
  }

  .clear_summary_input {
    right: 17% !important;
  }

  #sec-6-col2 {
    width: auto !important;
    border-radius: 30px;
  }

  #talk_to_book____ {
    flex-direction: column-reverse !important;
  }

  .sec-img {
    width: 100%;
    object-fit: contain;
    margin-top: 6px;
  }
}

@media (min-width: 518px) and (max-width: 991px) {
  #sec-6-col2 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .sec-6-crown {
    left: 2em !important;
  }
}

@media (max-width: 575px) {
  .complete_flow_lines div {
    width: 110px !important;
  }

  .p-block-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  #sec_btn {
    padding: 12px 0px !important;
    width: 150px;
    font-size: 15px;
  }

  .sec-img {
    width: 100%;
    object-fit: contain;
    margin-top: -30px;
  }

  .textArea_main {
    width: 100% !important;
  }

  .essay_pdf_main {
    width: 100% !important;
    height: 150px;
  }

  .select_princing_tabs__ {
    width: 80%;
  }

  .select_princing_tabs__ li {
    font-size: 11px;
    padding: 8px 13px !important;
  }
}

@media (max-width: 527px) {
  .complete_flow_lines div {
    width: 90px !important;
  }
  .banner_font_custom {
    font-size: 12px;
  }
}

@media (max-width: 462px) {
  .complete_flow_lines div {
    width: 70px !important;
  }
}

@media (max-width: 402px) {
  .complete_flow_lines div {
    width: 55px !important;
  }
}

@media (min-width: 318px) and (max-width: 517px) {
  #sec-6-col2 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sec-6-crown {
    left: 0 !important;
  }
}

@media (max-width: 317px) {
  .payment-button {
    font-size: 11px !important;
  }
  #sec-6-col2 {
    padding: 70px 10px 0;
  }

  #sec-6-col1 {
    padding: 0;
  }

  .sec-6-crown {
    left: -1em !important;
  }

  .seven-sec-heading {
    padding: 0 !important;
  }
}

@media (max-width: 430px) {
  .payment-button {
    font-size: 14px !important;
  }
  #sec-6-sub-heading {
    width: auto !important;
  }

  .new_history_btn {
    width: 43% !important;
    font-size: 14px !important;
    padding: 5px 10px !important;
  }

  .book-feed-heading {
    font-size: 16px !important;
  }
}

@media (max-width: 639px) {
  .dialog-box {
    display: none;
  }

  .essay-tool-txt {
    font-size: 26px !important;
  }

  .essay-container {
    margin: auto;
    margin-top: 20px !important;
  }
}

.sectionSix {
  margin-top: 135px;
  color: #424242;
}

.sec-6-div-2 {
  background: #ffffff;
  border-radius: 25px 25px 0 0;
  height: 519px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sec-6-cols {
  display: flex;
  flex-direction: column;
  width: 31em;
}

.sec-6-img {
  margin: 30px 0 15px 0;
}

.sec-6-col-2-div {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #18181b;
}

.sec-6-col-2-div1 {
  margin: 6px 0 19px 0;
  font-size: 14px;
  letter-spacing: 2px;
}

.sec-6-div {
  margin: 28px 0;
  width: -webkit-fill-available;
  padding-left: 45px;
}

.sec-6-points-div {
  display: flex;
}

.sec-6-tick-icon {
  margin-right: 11px;
}

.sec-6-div-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #6b7280;
  margin-bottom: 13px;
}

.sec-6-btn {
  background: #18181b !important;
  width: -webkit-fill-available !important;
  margin: 0 45px;
}

.sec-6-crown {
  width: 45px;
  position: absolute;
  top: 2em;
  left: 7em;
}

.Seven-Section {
  margin-top: 144px;
  margin-bottom: 100px;
}

.seven-sec-heading {
  font-size: 42px;
  font-weight: 700;
  line-height: 61px;
  text-align: center;
  color: #1f2937;
  padding: 23px 58px;
  line-height: 52px;
}

.sec-7-row {
  justify-content: center;
  margin-top: 24px;
  padding: 0 1em;
}

.sec-7-img {
  width: 500px;
  height: 500px;
  margin-bottom: 2em;
}

.sec-7-row-1 {
  justify-content: center;
  margin-top: 35px;
}

.sec-7-img-1 {
  width: 186px;
  padding: 0;
  margin: 0 0 12px 12px;
  cursor: pointer;
}

.copyright {
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

#sec-4-dialog {
  position: relative;
}

.dialog-box {
  position: absolute;
  top: 14.5em;
  left: 22em;
}

.msg-div {
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}

.otp-counter {
  color: #6366f1;
}

.sec-5-col-1 {
  margin-bottom: 2em;
}

@media (max-width: 450px) {
  .Second-Section {
    padding: 0;
  }

  .Third-Section {
    padding-top: 0;
  }

  .Fourth-Section {
    margin-top: 0;
    margin-bottom: 7em;
  }

  .sectionSix {
    margin: 6em 0;
  }

  .sec-7-img {
    height: auto;
  }

  .seven-sec-heading {
    font-size: 32px;
    line-height: 42px;
  }

  #sec-6-col2 {
    box-shadow: 0px 1px 10px 0px #e3e3e3;
  }
}

/* Essay Board */
.essay-container {
  margin: auto;
  margin-top: 50px;
  width: 100%;
}

.essay-tool-txt {
  font-size: 42px;
  padding-bottom: 10px;
  font-weight: 700;
}

.essay-question-txt {
  min-height: 207px;
  padding: 8px;
  color: #9c9c9c;
  border: 2px solid #9c9c9c;
  border-radius: 10px;
}

.essay-question-btn {
  margin: 20px 0px;
  width: 120px;
  background-color: #6366f1 !important;
  padding: 10px;
}

.essay-view-data {
  background-color: #fff;
  width: 100%;
  color: #14131e !important;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}

.select-col {
  width: 100%;
  padding: 10px;
  border-radius: 9px;
  margin-top: 4px;
  border: 2px solid #9c9c9c;
  color: #9c9c9c;
}

#Myfiles {
  display: none;
}

.Myfiles_label {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  cursor: pointer;
  border: 2px dashed #bbb9f9;
}

.Myfiles_label svg {
  width: 200px;
  height: 30px;
  color: #6366f1;
}

.upload_pdf {
  font-size: 16px;
  font-weight: 400;
  color: #6366f1 !important;
  margin-top: 10px;
}

.ai-color {
  color: #6366f1;
}

.p-block-instructions {
  font-size: 30px;
  font-weight: 700;
  line-height: 61px;
  text-align: center;
  color: #1f2937;
}

.p-block-count {
  font-size: 16px;
  color: #6366f1;
  font-weight: 700;
}

.p-block-main-div {
  padding: 0.5em 1em;
  border: 1px solid #e0e7ff;
  background: #e0e7ff;
  border-radius: 9px;
  width: fit-content;
  display: flex;
}

.app-logo {
  margin-top: 20px;
  display: flex;
}

#sub-container-1 {
  margin-top: 68px;
  justify-content: space-between;
}

#sub-container-2 {
  margin-top: 5px;
  justify-content: space-between;
}

#p-block-2 {
  padding-top: 140px;
}

#p-block-4 {
  padding-top: 164px;
}

.dotted-line-img {
  position: absolute;
  top: 21em;
  width: 565px;
  left: 25em;
}

.m-1-container {
  position: relative;
}

.img-z-index {
  position: relative;
  z-index: 99;
}

.add_prompt {
  font-size: 25px;
  font-weight: 600;
}

.range-slider__tooltip__label {
  background-color: #fff !important;
  color: black !important;
  font-weight: 500 !important;
}

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret {
  display: None !important;
}

@media (max-width: 1399px) {
  .dotted-line-img {
    display: none;
  }

  .p-blocks {
    padding-top: 2em !important;
  }

  .p-block-instructions {
    font-size: 18px;
  }

  #m-container {
    padding-bottom: 2em;
  }

  #sub-container-1 {
    justify-content: center;
  }

  #sub-container-2 {
    justify-content: center;
  }

  .p-block {
    margin: 0;
  }

  .p-block-heading {
    font-size: 22px;
    text-align: center;
    margin-top: 15px;
  }

  .p-block-div {
    font-size: 17px;
    text-align: center;
  }

  .sec-7-img-1 {
    margin-bottom: 0;
  }

  .app-logo {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 315px) {
  .p-block-instructions {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  #sec_btn {
    padding: 12px 34px;
  }

  .lecture-feed-heading input {
    font-size: 14px;
  }

  .third-section-1 {
    justify-content: center;
  }

  .third-section {
    margin-bottom: 15px;
  }

  .third-section-3 button {
    margin-top: 15px;
  }

  .third-section-3 div {
    margin-top: 15px !important;
  }

  .tik-tok-content {
    font-size: 17px;
  }

  .third-sec-h1 {
    font-size: 25px;
    margin-right: 0;
  }

  .third-sec-div {
    margin-right: 0;
  }

  .video-btn {
    width: 3em;
  }

  #sec-5-heading {
    font-size: 25px;
    line-height: 35px;
    text-align: center;
  }

  .third-section-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .third-section-2 {
    text-align: center;
    margin-top: 15px;
  }

  .third-section-btn {
    margin-right: 0 !important;
  }

  .fourth-section {
    margin-bottom: 15px;
  }

  .p-block-heading {
    margin-top: 10px;
    padding-left: 0;
  }

  .p-block-div {
    padding-left: 0;
    margin-top: 0;
  }

  .section-five-col {
    align-items: center;
  }

  #sec-5-sub-heading {
    text-align: center;
    margin-bottom: 15px;
  }

  #sec-6-heading {
    font-size: 25px;
    line-height: 40px;
  }

  #sec-6-col1 {
    padding: 0 2em;
    height: 430px;
  }

  .p-block {
    margin-bottom: 2em;
  }

  .photo-block {
    margin: 0 1em;
  }

  .sec-5-col-1 {
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 15px;
  }

  .fourth-sec-heading {
    padding-top: 15px !important;
    padding-left: 2em;
    padding-right: 2em;
  }

  .sectionOneHeadingCol span h1 {
    padding-top: 15px;
  }

  .swipe-sub-heading {
    margin-top: 15px;
  }

  .btn_div {
    margin-top: 15px;
  }

  .Seven-Section {
    margin-top: 7em !important;
  }

  .seven-sec-heading {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0;
  }

  .sec-7-row {
    margin-top: 0;
  }

  .Fourth-Section {
    margin-top: 0;
  }
}

@media (min-width: 551px) and (max-width: 950px) {
  .Fourth-Section {
    margin-top: 80px;
    margin-bottom: 100px;
  }

  #sec-5-heading {
    font-size: 32px;
    line-height: 35px;
  }

  #sec-5-sub-heading {
    margin-bottom: 15px;
  }

  .sectionSix {
    margin-top: 110px;
  }

  #sec-6-col1 {
    padding: 0 4em;
    height: 430px;
  }

  .Seven-Section {
    margin-top: 7em !important;
  }

  .p-block {
    margin-top: 1em;
  }
}

@media (max-width: 298px) {
  .section-five-col {
    padding: 0;
  }

  .heading h1 {
    font-size: 20px !important;
  }

  .credites {
    margin: 0 !important;
  }

  .user_message_send_btn {
    bottom: 28px !important;
    right: 16px !important;
  }
}

@media (min-width: 768px) and (max-width: 912px) {
  #sec_btn {
    padding: 12px 10px;
    font-size: 14px;
    width: 125px;
  }

  .third-section-btn {
    margin: 25px 6px 0 0;
  }
}

@media (min-width: 540px) and (max-width: 1399px) {
  .p-block-div {
    width: 30em;
  }
}

#modal-header {
  border-bottom: 1px solid #dee2e6 !important;
  margin: 0;
}

.otp-input-box input {
  margin-right: 8px !important;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.section-3-div {
  padding: 0 5em;
}

.loginModel-p {
  margin-right: 2em;
  font-size: 14px;
}

@media (max-width: 991px) {
  .section-3-div {
    padding: 0 1em;
  }

  .Seven-Section {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tinder-baseline p {
    margin: 0;
  }

  .loginModel-p {
    margin-right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1500px) {
  #sec_btn {
    padding: 16px 20px;
    font-size: 14px;
  }

  .third-section-btn {
    margin: 25px 15px 0 0;
  }

  .section-3-div {
    padding: 0 1em;
  }
}

.privacy_policy_heading {
  font-size: 30px;
  font-weight: 700;
}

.privacy_ai_color__ {
  color: #6366f1;
}

.sec-7-row-1 a {
  width: auto;
  margin-bottom: 1em;
}

.heading h1 {
  font-weight: 700;
  font-size: 42px;
}

.heading p {
  color: #71717a;
  font-size: 16px;
  font-weight: 400;
}

.talkbook_heading____ {
  display: flex;
  justify-content: space-between;
}

.chat_feed___ {
  height: 635px;
  border: 1px solid #e3e3e3;
  border-radius: 17px;
  padding: 15px;
  position: relative;
}

.summary_chat_feed___ {
  height: 635px;
  border-radius: 17px;
  padding: 15px;
  position: relative;
}

.chat-feed-content {
  height: 90%;
  overflow-y: scroll;
  padding: 1em;
}

.recieved_message {
  display: inline-block;
  color: #000;
  background-color: #efefff;
  border: 1px solid #e3e3e3;
  padding: 5px;
  border-radius: 0px 10px 10px 10px;
  max-width: 624px;
  padding: 12px 9px;
}

.send_message {
  display: inline-block;
  color: #000;
  background-color: #f7f7f7;
  border: 1px solid #e3e3e3;
  padding: 5px;
  border-radius: 10px 0px 10px 10px;
  max-width: 624px;
  padding: 12px 9px;
}

.recieved_message_time {
  font-size: 14px;
  color: rgb(156, 156, 156);
}

.user_search__ {
  position: absolute;
  bottom: 0;
  width: 95%;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 28px;
  padding: 10px;
  font-size: 16px;
}

.user_message_send_btn {
  width: 43px;
  height: 30px;
  position: absolute;
  bottom: 30px;
  right: 38px;
  padding: 0 !important;
  border-radius: 30px;
  background-color: #6366f1 !important;
  line-height: 13px !important;
}

.books_feed__ {
  height: 635px;
  border: 1px solid #e3e3e3;
  border-radius: 17px;
  padding: 15px;
  position: relative;
}

.book-feed-heading {
  font-size: 20px;
  font-weight: 700;
}

.book-feed-content {
  height: 77%;
}

.book-feed-books {
  overflow: auto !important;
  padding-right: 1em;
  height: 90%;
}

.book-search {
  position: relative;
}

.book-search img {
  position: absolute;
  top: 0.8em;
  left: 1em;
}

img {
  object-fit: contain;
}
.book-search input {
  margin-top: 18px;
  margin-bottom: 27px;
  border-radius: 28px;
  padding: 10px;
  font-size: 16px;
  padding-left: 3em;
}

.book {
  display: flex;
  position: relative;
  align-items: center;
}

.book-feed-books hr {
  background-color: #e0e0e0;
  height: 3px;
}

.book-content {
  margin-left: 16px;
}

.book-content-heading {
  font-size: 18px;
  font-weight: 700;
}

.chatbot-content-heading {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.book-content-pages {
  font-size: 13px;
}

.book-content-details {
  font-size: 12px;
}

.book-checkbox {
  display: flex;
  right: 0;
}

.book-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 28px;
}

.book-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.book-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.book-checkbox input[type="checkbox"]:checked + label {
  background-color: #6363f1;
  border-color: #6363f1;
}

.book-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

#talk-to-book-pdf-label {
  height: 8.5em;
}

.cs-main-container {
  border: none !important;
}

.cs-main-container:has(.chat-container) {
  height: 90% !important;
}

.cs-main-container:has(.chat-container) {
  height: 90% !important;
}

.cs-chat-container .cs-message-input {
  border-top: none !important;
  position: relative;
}

.cs-message--incoming .cs-message__content {
  background-color: #efefff !important;
  border-radius: 0.7em 0.7em 0.7em 0 !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: #f7f7f7 !important;
  border-radius: 0.7em 0.7em 0 0.7em !important;
}

.cs-message-input__content-editor-wrapper {
  background-color: white !important;
  border: 1px solid #d1d2fb;
  border-radius: 22px !important;
  padding: 0.9em 1.5em !important;
  margin: 0 !important;
}

.cs-message-input__content-editor-container {
  background-color: white !important;
}

.cs-message-input__content-editor {
  background-color: white !important;
}

.cs-message-input__tools {
  position: absolute;
  right: 12px;
  top: 12px;
}

.cs-button {
  color: white !important;
  background-color: #6366f1 !important;
  width: 55px;
  border: 1px solid #6366f1 !important;
  border-radius: 22px !important;
  opacity: 1 !important;
}

.main-container {
  height: 90% !important;
}

@media (max-width: 991px) {
  .row-1 {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .row-2 {
    padding-top: 1em;
    padding-bottom: 2em;
  }

  .left-side-content {
    padding-bottom: 20px !important;
  }
}

.Myfiles_label2 {
  height: 8.5em;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  cursor: pointer;
  border: 2px dashed #6366f1;
}

.talk-to-book-loader {
  position: absolute;
  height: 100%;
  width: 97%;
  display: flex;
  align-items: center;
  background-color: #71717142;
  z-index: 999999999;
  border-radius: 10px;
}

.incoming {
  display: block !important;
  margin-top: 5px !important;
}

.pdf-opacity {
  opacity: 0.6;
}

.books-not-available {
  color: #c53131;
  font-size: 18px;
  text-align: center;
}

.book-not-available-div {
  display: flex;
  align-items: center;
}

.TailSpin_Stripe_Coupon2 {
  display: flex;
  justify-content: center !important;
  margin-top: 5em;
}

.book-img img {
  border-radius: 10px;
  width: 90px;
  height: 90px;
  object-fit: contain;
}

.TailSpin_Stripe_Coupon2-div {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.delete_text_book svg:nth-child(1) {
  width: 19px !important;
  height: 19px !important;
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.cs-button:disabled {
  opacity: 0.38 !important;
}

#activeBook {
  pointer-events: none;
}

.loading_progress___ {
  width: 50px !important;
  height: 50px !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #6366f1 !important;
  font-size: 25px !important;
  dominant-baseline: middle;
  text-anchor: middle;
  font-weight: 700 !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #6366f1 !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.UploadProgress {
  width: 200px !important;
  height: 30px !important;
}

.UploadProgress .progress-bar {
  height: 30px !important;
}

.dot-elastic {
  margin-left: 8px;
  position: relative;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dot-elastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -6px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dot-elastic-before 1s infinite linear;
}

.dot-elastic::after {
  left: 6px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dot-elastic-after 1s infinite linear;
}

.alternate-login-side-line {
  width: -webkit-fill-available;
  height: 50%;
  border-bottom: 2px solid #58525259;
}

.email-modal-close-btn {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
}

.label-email {
  font-size: 17px;
}

.label-email:first-letter {
  text-transform: capitalize;
}

.select-email {
  border: 2px solid #837e7eb3;
  height: 70px;
  border-radius: 8px;
  font-size: 17px;
  padding: 4px 8px;
  text-transform: capitalize;
}

.label-num-email {
  font-size: 17px;
}

#num-email {
  margin-bottom: 20px;
  margin-top: 8px;
  padding: 4px 8px;
  width: 100%;
  border: 2px solid #837e7eb3;
  border-radius: 6px;
  font-size: 17px;
}

#sign-up-btn {
  background: white !important;
  color: #6366f1;
  font-weight: bold;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1.5);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 1.5);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.cs-typing-indicator__indicator {
  position: absolute !important;
  left: 60px !important;
  top: 20px !important;
}

.select_page_button {
  width: 200px;
  justify-self: center;
  margin: auto;
  background: #6366f1;
  color: #fff;
  border-radius: 7px;
}

.select_page_button:hover {
  color: #fff !important;
}

@media (hover: none) {
  #sec-7-img-pic-1 {
    display: none;
  }
}

.select_book_pages__ {
  font-size: 12px;
  color: red;
}

.nav-active {
  color: #fff !important;
  font-weight: 700;
}

.cs-message-list .cs-typing-indicator {
  display: inline-table !important;
  padding: 10px 0px 0px 9px !important;
}

.cs-chat-container .cs-message-input {
  margin-top: 15px !important;
}

.cs-message-input__content-editor {
  width: 93% !important;
}

.price-span {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #a1a1aa;
}

.price {
  font-weight: 700;
  font-size: 48px;
  line-height: 49px;
  color: #18181b;
}

.extension-logo {
  width: 160px;
  margin-bottom: 10px;
}

.logo-name {
  margin-left: 13px;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
  color: #ffffff;
}

.navbar {
  background-color: black;
  margin: 0 !important;
  padding: 35px 0;
}

.tag-number {
  font-size: 60px;
  font-weight: bold;
  color: #333333;
  height: 60px;
  display: flex;
  justify-content: center;
}

.tag-headline {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  width: 158px;
  height: 28px;
  margin-top: 13px;
  display: flex;
  justify-content: center;
  width: inherit;
}

.left-side-content {
  padding-left: 80px;
}

.left-side-margin {
  padding-right: 170px;
}

.left-side-margin-1 {
  padding-right: 120px;
}

.left-side-section {
  padding-top: 0 !important;
  padding-bottom: 48px !important;
}

.android-icon {
  padding-top: 25px;
}

.footer {
  background: #0a152f !important;
  padding: 0 !important;
}

.footer-col-1 {
  padding: 51px 0 35px 0;
}

.footer-hr {
  color: rgba(216, 228, 238, 0.5);
}

.footer-col-2 {
  padding: 32px 0 72px 0;
}

.footer-span {
  padding-left: 26px;
  color: rgba(216, 228, 238, 1);
}

.AnswerAi_Main_IntroVideo {
  width: 100%;
  object-fit: contain;
}

.tag-div {
  padding: 0 10px;
}

.navbar-toggler {
  width: 70px !important;
}

.refer-code-url {
  border: 2px solid #6d757d;
  padding: 8px 10px 8px 20px;
  border-radius: 22px;
}

.refer-code-container {
  background-color: #6d757d1f;
  margin: 0 50px;
  padding: 40px;
  border-radius: 8px;
}

.referral-copy-line {
  font-size: 12px;
  margin-top: 20px;
}

.copy-svg {
  margin-left: 20px;
  color: black;
  cursor: pointer;
}

.share-link {
  border: 1px solid #6d757d;
  padding: 6px;
  border-radius: 20px;
  cursor: pointer;
}

.pricing-plans-content {
  padding: 0 80px;
}

.margin-x {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.main-div-container {
  min-height: 850px;
}

@media (max-width: 1535px) {
  .refer-responsive-heading {
    font-size: 1.5rem !important;
  }

  .refer-responsive-sub-heading {
    font-size: 1rem !important;
  }

  .referral-copy-line {
    font-size: 10px !important;
  }

  .refer-code-url {
    padding: 8px 10px !important;
  }

  #refer-url {
    font-size: 12px !important;
  }

  .copy-svg {
    margin-left: 10px !important;
    font-size: 1rem !important;
  }

  .share-link {
    font-size: 2rem !important;
  }

  .twitter-logo-responsive {
    width: 30px !important;
    height: 30px !important;
  }
}

@media (max-width: 1279px) {
  #refer-url {
    font-size: 10px !important;
  }

  .cookie-consent-container {
    display: block !important;
  }
  .cookie-content {
    display: block !important;
    margin: 0px !important;
    margin-left: 7px !important;
  }
  .Cookie_preferences_button {
    margin-top: 20px !important;
  }
}

@media (max-width: 1199px) {
  .login-btn-group {
    margin: 1em;
  }
}

@media (max-width: 1166px) {
  .main-container {
    height: 85% !important;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .logo-name {
    font-size: 20px;
  }

  .answer-ai-icon {
    width: 30px;
  }

  .third-sec-div {
    padding-top: 15px;
  }

  .left-side-margin {
    padding-right: 100px;
  }
}

@media (max-width: 992px) {
  .handle_save_btn {
    bottom: 43px !important;
  }
  .pricing-plans-content {
    padding: 0 10px;
  }
  .handle_save_btn_youtube {
    bottom: -0.8em !important;
  }
}

@media (min-width: 341px) and (max-width: 767px) {
  .logo-name {
    font-size: 18px;
  }

  .clear_summary_input {
    right: 23% !important;
  }

  .answer-ai-icon {
    width: 30px;
  }

  .copyright {
    font-size: 12px;
  }

  .footer-span {
    font-size: 12px;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .footer-terms {
    display: flex;
    flex-direction: column;
  }

  .footer-content {
    padding: 0;
  }
}

@media (max-width: 767px) {
  #refer-url {
    word-break: break-all !important;
  }

  .copy-svg {
    position: relative !important;
    top: 4px !important;
  }

  .share-link-icons {
    margin-top: 10px !important;
    justify-content: center !important;
  }

  .refer-responsive-image {
    display: flex !important;
    align-items: center !important;
  }

  .div-border-responsive {
    border-radius: 1rem !important;
  }

  .div-margin-height-responsive {
    margin-top: 50px;
    min-height: 600px;
  }

  .main-container {
    min-height: 95% !important;
  }
}

@media (max-width: 639px) {
  .share-modal-link {
    display: block !important;
  }

  .refer-code-container {
    padding: 20px !important;
  }

  .refer-responsive-heading {
    font-size: 14px !important;
  }

  .refer-responsive-sub-heading {
    font-size: 12px !important;
  }

  .refer-code-url {
    width: fit-content;
    position: relative;
    margin: auto;
  }

  .copy-svg {
    top: 0px !important;
  }
}

@media (max-width: 420px) {
  .cancel_subs_custom_style
    > .css-13cymwt-control
    > .css-1fdsijx-ValueContainer
    > .css-1jqq78o-placeholder {
    font-size: 12px;
  }

  .refer-code-container {
    margin: 0px !important;
  }

  .app-logo {
    flex-direction: column;
    align-items: center;
  }

  .sec-7-img-1 {
    margin-top: 10px;
  }

  .font-responsive {
    font-size: 12px;
  }

  .margin-x {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}

@media (min-width: 341px) and (max-width: 650px) {
  .tag-number {
    font-size: 30px !important;
    height: 22px !important;
  }

  .tag-headline {
    font-size: 12px !important;
    text-align: center;
  }

  .footered {
    padding: 20px;
  }

  .footer-col-2 {
    padding: 20px 0;
  }

  .copyright {
    font-size: 10px;
  }

  .mobile-case {
    flex-direction: column-reverse !important;
  }
}

@media (max-width: 340px) {
  .tag-div {
    padding: 1px;
  }

  .tag-number {
    font-size: 12px !important;
    height: 12px !important;
  }

  .tag-headline {
    font-size: 12px !important;
    text-align: center;
  }

  .logo-name {
    font-size: 12px;
    margin-left: 5px;
  }

  .answer-ai-icon {
    width: 25px;
  }

  #nav_btn {
    padding: 12px 20px;
  }

  .subs_popup_fs_custom {
    font-size: medium;
  }

  .responsive_button2_custom_Style {
    margin-right: 0.5rem !important;
  }
}

@media (max-width: 469px) {
  .app-store-icon {
    margin-left: 0 !important;
  }

  .clear_summary_input {
    right: 31% !important;
  }

  .lecture-feed-heading input {
    font-size: 12px;
  }

  .clear_summary_input {
    top: 0 !important;
  }

  .responsive_button_custom_Style {
    padding-right: 0rem !important;
  }
}

@media (max-width: 767px) {
  .footer-logo {
    justify-content: center;
    padding-bottom: 20px;
  }

  .social-media-icons {
    display: flex;
    justify-content: center;
  }

  .yt_summary_button {
    margin-top: 10px;
    margin-left: 0px !important;
  }

  .lecture-feed-heading {
    display: block !important;
  }

  .clear_summary_input {
    right: 1% !important;
    top: 2% !important;
  }

  .footer-col-2 {
    flex-direction: column-reverse;
  }
}

@media (min-width: 280px) and (max-width: 991px) {
  .left-side-section {
    padding: 2em !important;
  }
}

.ps__thumb-y {
  background-color: #d3d0d0 !important;
}

.msg-div img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  cursor: pointer;
  filter: invert(1);
}

.hide-form input:last-child {
  margin-left: 0 !important;
}

.youtube_summary_ai____ {
  width: 100%;
  height: 200px;
  border-radius: 14px;
}

.allYtSummary {
  height: 390px;
  overflow-y: auto;
}

#youtube_loader {
  display: flex !important;
  margin: auto;
  position: absolute;
  top: 70%;
  left: 40%;
}

.yt_summary_button {
  width: fit-content;
  font-size: 16px;
}

.iframeLoader {
  margin: auto;
  display: flex;
  top: 38%;
  left: 38%;
  position: absolute;
}

.yt_Timestamp {
  color: rgb(6, 95, 212);
}

.clear_summary_input {
  width: fit-content;
  font-size: 16px;
  position: absolute;
  right: 9%;
  font-size: 20px;
  color: #000;
  top: 10%;
}

.clear_summary_input:hover {
  color: #000;
}

.clear_summary_input:disabled {
  opacity: 0.38;
}

.yt_summary_button:disabled {
  opacity: 0.38;
}

.allYtSummary {
  padding: 2%;
  border-radius: 14px;
}

#BGallYtSummary {
  background-color: #efefff;
}

.leacture_summary_feed__ {
  height: 635px;
  border-radius: 17px;
  padding: 15px;
  position: relative;
}

.allYtSummary::-webkit-scrollbar {
  width: 8px;
}

.allYtSummary::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  height: 100px;
}

/* Pricing Plan Page */

.pricing-plans {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.plan {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 200px;
  text-align: center;
}

.plan h2 {
  color: #333;
  margin-bottom: 10px;
}

.plan h3 {
  color: #666;
  margin-bottom: 20px;
}

.plan ul {
  list-style: none;
  padding: 0;
}

.plan ul li {
  margin-bottom: 10px;
  color: #333;
}

/* Existing styles */

.plan button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.plan button:hover {
  background-color: #0056b3;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 10px;
}

#pricing-modal {
  position: relative;
  border-radius: 20px;
}

/* chatbot */
.active_his_title {
  background-color: #efefff;
  border-radius: 8px;
  border-bottom: none;
}

.show_border {
  border-bottom: 1px solid #ccc;
}

.show_border:hover {
  background: rgba(239, 239, 255, 0.4);
}

.chat-time {
  color: rgba(77, 77, 77, 0.87);
  background-color: transparent;
  font-size: 0.8em;
}

.chat_response-div {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: 10px;
}

.chat_question-div {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-bottom: 10px;
}

.chat_response {
  width: fit-content;
  background-color: #efefff;
  border-radius: 0.7em 0.7em 0.7em 0px;
  padding: 9px 8px;
  white-space: pre-wrap;
  max-width: 85%;
}

.chat_question {
  background-color: #f7f7f7;
  width: fit-content;
  border-radius: 0.7em 0.7em 0 0.7em;
  padding: 9px 8px;
  max-width: 85%;
}

.new_history_btn {
  width: 32%;
  font-size: 18px;
  padding: 5px 10px;
}

.book2 {
  position: relative;
  align-items: center;
}

.handle_save_btn {
  cursor: pointer;
  position: absolute;
  bottom: 26px;
  z-index: 1;
  width: 55px;
  right: 39px;
  background-color: #6366f1;
  padding: 0px;
  font-size: 1.2em;
  height: 38px;
  border-radius: 22px;
}
.handle_save_btn_youtube {
  cursor: pointer;
  position: absolute;
  bottom: 0.9em;
  z-index: 1;
  width: 55px;
  right: 12px;
  background-color: #6366f1;
  padding: 0px;
  font-size: 1.2em;
  height: 38px;
  border-radius: 22px;
}

.handle_save_btn > svg {
  display: inline !important;
}
.handle_save_btn_youtube > svg {
  display: inline !important;
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
}

.cancel_subs_custom_style
  > .css-13cymwt-control
  > .css-1fdsijx-ValueContainer
  > .css-1jqq78o-placeholder {
  color: #767f86;
  font-weight: 400;
}

.cancel_subs_custom_style
  > .css-13cymwt-control
  > .css-1fdsijx-ValueContainer
  > .css-1dimb5e-singleValue {
  color: #767f86 !important;
  font-weight: 400;
}

.cancel_subs_custom_style > .css-1nmdiq5-menu {
  color: #767f86;
  font-weight: 400;
}

.cancel_reson_inputField {
  color: #767f86 !important;
  font-weight: 400 !important;
}

.cancel_reson_inputField ::placeholder {
  color: #767f86 !important;
  font-weight: 400 !important;
}

/* subscription cancel popup custom style end */
.mobile-border {
  border: #6366f1;
  border-style: solid;
  border-width: 4px;
}

/* This media query applies for screens smaller than 768px (typical breakpoint for mobile devices) */
@media (max-width: 767px) {
  .mobile-border {
    top: 1.4em;
  }
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background: grey !important;
}

#paymentModal {
  border-radius: 20px;
  background-color: #000;
  border: 2px solid #fff;
}

#mobile_app_display {
  background: linear-gradient(
      rgba(99, 102, 241, 0.2) 0%,
      rgb(18 18 18 / 0%) 80%
    ),
    rgb(18 18 18 / 0%);
}

.model {
  padding-left: 0px !important;
}

.comlete_signup_heading {
  font-size: 25px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px !important;
}

.comlete_signup_subheading {
  font-size: 14px;
}

.responsive_button2_custom_Style:hover {
  color: #000 !important;
  background-color: white;
}

.modal {
  padding-left: 0px !important;
}

.popup-header {
  font-size: 18px;
  margin-bottom: 10px;
}

.popup-buttons {
  display: flex;
  margin-top: 20px;
}

.popup-buttons button {
  padding: 6px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
}

.continue-button {
  background-color: #4caf50;
  color: white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.pricing_modal_custom {
  display: flex;
  width: 504px;
  padding: 16px 24px 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.discount_image_custom {
  position: absolute;
  right: 0px;
  overflow: hidden;
  border-radius: 0px 18px 0px 0px;
}
.select_book_pages___:hover {
  text-decoration: underline;
}
.extension____email___ {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.cookie-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #000 !important;
}

.cookie-decline-button {
  flex: none !important;
}
.cookies-wrapper-button {
  display: flex;
}
.cookies-wrapper-button > button {
  padding: 8px 40px !important;
  width: fit-content !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  color: #6366f1 !important;
  border: 3px solid #6366f1 !important;
}

.Cookie_preferences_button {
  padding: 8px 40px !important;
  width: fit-content !important;
  background-color: #fff !important;
  color: #6366f1 !important;
  border: 3px solid #6366f1 !important;
}

.cookie-consent-container {
  align-items: center !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.cookie-preferences {
  padding: 20px;
}

.toggle-group {
  margin-bottom: 10px;
}

.toggle-group label {
  display: flex;
  align-items: center;
}

.toggle-group input[type="checkbox"] {
  margin-right: 10px;
}

.cookies_switch {
  padding: 5px 0px;
}

.cookies_switch label {
  font-size: 17px;
  margin-top: 3px;
  margin-left: 10px;
}

.cookies_switch > .form-switch .form-check-input {
  width: 50px !important;
  height: 25px !important;
}

.Cookie_preferences_button2 {
  padding: 4px 13px !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 3px solid #6366f1 !important;
}

.conditions_policy_accept {
  font-size: 11px;
  text-align: center;
}

.testimonial {
  width: 150px; /* Increase width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 20px;
}

.hero {
  background: linear-gradient(to bottom, #000000, #1f2937);
}
.mobile-feature {
  background: linear-gradient(to top, #000000, #1f2937);
}
.institution-hero {
  background: linear-gradient(to top, #add8e6, #d0d1fa);
}
.institution-secondary-div {
  background: linear-gradient(to bottom, #add8e6, #d0d1fa);
}

.regenerate_btn {
  background-color: #0d6efd !important;
  color: #fff !important;
}

.regenerate_btn:hover {
  background: #652aeb !important;
}
.audio_active {
  background-color: #f3f3fe;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}
.not_audio_active {
  padding: 16px;
  cursor: pointer;
}
.audio_edit_option {
  right: 0px;
  top: 25px;
  font-size: 14px;
  border-radius: 10px;
  z-index: 99;
  border: 2px solid lightgray;
}
.user_stop_listen {
  color: red !important;
}
.audio_active {
  background-color: #f3f3fe;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}
.not_audio_active {
  padding: 16px;
  cursor: pointer;
}
.audio_edit_option {
  right: 0px;
  top: 25px;
  font-size: 14px;
  border-radius: 10px;
  z-index: 99;
  border: 2px solid lightgray;
}
.user_stop_listen {
  color: red !important;
}

.susbcription_modal {
  border-bottom: 1px solid #dee2e6 !important;
}
